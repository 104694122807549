<template>
    <div :class="mobile ? 'blue-section-mobile' : 'blue-section'" style="width: 100%;">
              <div style="width: 100%; max-width: 1500px; margin: 0 auto; display: flex;" :style="{'flex-direction': mobile ? 'column' : 'row'}">

      <div v-if="mobile" style="flex: 1; text-align: center; margin: 0 auto; margin-bottom: 20px !important; ">
        <div class="section-title-mobile" id="Mission">Our Mission</div>
        <div class="section-paragraph-mobile">To harness the power of AI and open-source data to capture early epidemic signals globally and enable early detection of epidemics, leading to prevention of global spread.</div>
        <br/>
        <div class="section-paragraph-mobile">To provide real-time decision support tools including risk-analysis, prediction, simulation and modelling to help government and non-government stakeholders mitigate risk.</div>
      </div>
      <div style="flex: 1; text-align: center;">
        <flowchart />
      </div>
      <div v-if="!mobile" style="flex: 1; text-align: right; ">
        <div class="section-title" id="Mission">Our Mission</div>
        <div style="width: 70%;float: right">
        <div class="section-paragraph">To harness the power of AI and open-source data to capture early epidemic signals globally and enable early detection of epidemics, leading to prevention of global spread.</div>
        <br/>
        <div class="section-paragraph">To provide real-time decision support tools including risk-analysis, prediction, simulation and modelling to help government and non-government stakeholders mitigate risk.</div>
        <br/>
        <div class="section-paragraph">Hover over the flowchart to learn more.</div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import ReportTable from '@/components/ReportTable.vue'
import Flowchart from '@/components/about/Flowchart.vue'

export default {
  name: "Mission",
  components: {
      Flowchart
  },
  data: () => ({
    mobile: null,
    windowWidth: null,
    flowchartHover: {
      green: false,
      yellow: false,
      orange: false,
      red: false
    }
  }),
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    this.checkScreen()
  },
  destroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
      return;
    }
  },
};
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>