<template>
    <div id="imagine" style="width: 100%; background-color: #e6e8e8;">
        <div style="width: 100%; max-width: 1500px; margin: 0 auto; display: flex;">
            <div v-if="!mobile" id="imagine-left" class="imagine-transition" :class="{'black_and_white': pastImage}"
                  style="flex: 4; background-color: gray;
                    position: relative; height: inherit;
                    background-image: linear-gradient(270deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), image-set(url('/home/imagine-before.avif') type('image/avif'), url('/home/imagine-before.webp') type('image/webp'), url('/home/imagine-before.jpeg') type('image/jpeg'));
                    background-size: cover; background-repeat: no-repeat; background-position: left center;" >
                <svg id="imagine-left-wave" xmlns="http://www.w3.org/2000/svg" style="float: right;" preserveAspectRatio="xMinYMin meet" viewBox="-1000 380 961 900"><path fill="#e6e8e8" fill-opacity="1" d="M -489.537 0 L -478.361 32.858 C -466.792 65.647 -445.066 131.272 -395.957 192.677 C -347.364 253.462 -272.406 310.005 -260.598 376.972 C -248.617 444.924 -299.663 523.989 -298.392 592.814 C -296.606 662.259 -243.384 722.228 -199.593 784.571 C -156.497 846.427 -122.919 910.165 -121.77 980.026 C -120.777 1049.002 -150.175 1124.148 -133.443 1190.247 C -116.554 1257.232 -51.549 1314.921 -19.571 1344.822 L 12.627 1373.669 L 12.627 1373.669 L 6.671 1339.89 C 0 1306.111 0 1238.75 0 1170.798 C 0 1103.536 0 1035.88 0 968.913 C 0 900.961 0 833.009 0 766.043 C 0 698.386 0 631.124 0 571.77 C 0 495.811 0 569.77 0 375.77 C 0 212.77 0 375.77 0 224.77 C 0 32.77 0 224.77 0 65.77 L 0 0 Z"></path></svg>
            </div>
            <div id="description" :class="mobile ? 'white-section-mobile' : null" :style="!mobile ? 'flex: 3; background-color: #e6e8e8; text-align: center; padding: 50px 0; position: relative; min-width: 300px; color: #051238;' : 'text-align: center; margin: 0 auto;'">
                <div id="imagine-description">
                <div :class="mobile ? 'section-title-mobile' : 'section-title'">Imagine</div>
                <div :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph'">COVID detected before it spread worldwide through an early signal for severe pneumonia.</div>
                <br/>
                <div :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph'">The epidemic stopped in its tracks in 2019 and consigned to the archives of rare, serious outbreaks.</div>
                </div>
            </div>
            <div v-if="!mobile" id="imagine-right" class="imagine-transition" :class="{'black_and_white': !pastImage}"
                style="flex: 4; background-color: gray; position: relative;
                background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), image-set(url('/home/imagine-after.avif') type('image/avif'), url('/home/imagine-after.webp') type('image/webp'), url('/home/imagine-after.jpg') type('image/jpg'));
                background-size: cover; background-repeat: no-repeat; background-position: right center;">
                <svg id="imagine-right-wave" xmlns="http://www.w3.org/2000/svg" style="float: left;" preserveAspectRatio="xMinYMin meet" viewBox="50 380 961 900"><path fill="#e6e8e8" fill-opacity="1" d="M 489.537 0 L 478.361 32.858 C 466.792 65.647 445.066 131.272 395.957 192.677 C 347.364 253.462 272.406 310.005 260.598 376.972 C 248.617 444.924 299.663 523.989 298.392 592.814 C 296.606 662.259 243.384 722.228 199.593 784.571 C 156.497 846.427 122.919 910.165 121.77 980.026 C 120.777 1049.002 150.175 1124.148 133.443 1190.247 C 116.554 1257.232 51.549 1314.921 19.571 1344.822 L -12.627 1373.669 L -12.627 1373.669 L -6.671 1339.89 C 0 1306.111 0 1238.75 0 1170.798 C 0 1103.536 0 1035.88 0 968.913 C 0 900.961 0 833.009 0 766.043 C 0 698.386 0 631.124 0 571.77 C 0 495.811 0 569.77 0 375.77 C 0 212.77 0 375.77 0 224.77 C 0 32.77 0 224.77 0 65.77 L 0 0 Z"></path></svg>
            </div>
        </div>
        <div id="imagine-mobile" 
            v-if="mobile"
            class="imagine-transition"
            :class="{'black_and_white': !pastImage}"
            v-bind:style="{ 'background-image': `linear-gradient(0deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('${imagineMobileUrl}')`, 'background-size': 'cover', 'height': '25vh', 'background-position': imagineBackgroundPos }"
        />
    </div>
</template>

<script>
// @ is an alias to /src
// import ReportTable from '@/components/ReportTable.vue'

export default {
  name: 'Imagine',
  components: {
  },
  data: () => ({
      windowWidth: null,
      mobile: null,
      pastImage: false,
      imagineMobileUrl: null,
      imagineBackgroundPos: null
    }),
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
    window.removeEventListener('scroll', this.checkScroll);
  },
  mounted() {
    this.checkScreen();
    window.addEventListener('scroll', this.checkScroll);
    this.checkScroll();
  },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }

        if (document.getElementById("imagine-description")) {
          let innerSectionHeight = document.getElementById("imagine-description").clientHeight;
          if (document.getElementById("imagine-left")) {
            document.getElementById("imagine-left").setAttribute("style", "flex: 4; max-height:calc("+innerSectionHeight+ "px + 100px);background-color: gray; position: relative; height: inherit; background-image: linear-gradient(270deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/home/imagine-before.jpeg'); background-size: cover; background-repeat: no-repeat; background-position: left center;")
            document.getElementById("imagine-left").style.maxHeight = "calc(" + innerSectionHeight + "px + 100px)"
          }
          if (document.getElementById("imagine-left-wave")) {
            document.getElementById("imagine-left-wave").setAttribute("style", "float: right; height: calc("+innerSectionHeight+ "px + 100px);")
            document.getElementById("imagine-left-wave").style.height = "calc(" + innerSectionHeight + "px + 100px)"
          }
          if (document.getElementById("imagine-right")) {
            document.getElementById("imagine-right").setAttribute("style", "flex: 4; max-height: calc("+innerSectionHeight+"px + 100px);background-color: gray; position: relative; height: inherit; background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/home/imagine-after.jpg'); background-size: cover; background-repeat: no-repeat; background-position: right center;")
            document.getElementById("imagine-right").style.maxHeight = "calc(" + innerSectionHeight + "px + 100px)"
          }
          if (document.getElementById("imagine-right-wave")) {
            document.getElementById("imagine-right-wave").setAttribute("style", "float: left; height: calc("+innerSectionHeight+ "px + 100px);")
            document.getElementById("imagine-right-wave").style.height = "calc(" + innerSectionHeight + "px + 100px)"
          }
        }
        return;
      },
      checkScroll() {
        let targetElement = document.getElementById("imagine-description")
        if (targetElement !== null) {
            let elementLocation = this.mobile ? (targetElement.offsetTop - (targetElement.offsetHeight/2)) : (targetElement.offsetTop - (targetElement.offsetHeight/2) + (window.innerHeight/2))
          if (window.scrollY > elementLocation) {
            this.pastImage = true
          } else {
            this.pastImage = false
          }
        }

        if (this.mobile) {
            this.imagineMobileUrl = !this.pastImage ? '/home/imagine-before.jpeg' : '/home/imagine-after.jpg'
            this.imagineBackgroundPos = !this.pastImage ? 'left center' : 'right center'
        }
        return;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

iframe {
  overflow: hidden;
  pointer-events: auto;
}
</style>