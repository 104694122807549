<template>
    <div>
        <div :class="mobile ? 'blue-section-mobile' : 'blue-section'">
          <div style="max-width: 1500px; margin: 0 auto;">
          <div style="display:flex;">
            <div v-if="!mobile" style="flex: 2"></div>
            <div :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph'" style="text-align: center; flex: 3;">
              <div>Our vision is to be the centre of epidemic intelligence for global decision makers and prevent the next pandemic.</div>
              <br/>
              <div><span style="font-weight: bold">EPIWATCH</span> is an artificial intelligence-driven open-source outbreak observatory for early outbreak warnings and rapid risk analysis.</div>
              <br/>
              <br/>
              <div :class="mobile ? 'section-title-mobile' : 'section-title'">Our services</div>
            </div>
            <div v-if="!mobile" style="flex: 2"></div>
          </div>
          <v-container fluid>
            <v-row v-if="mobile">
                <v-row style="margin: 5px 0px">
                  <v-col v-for="(item, index) in serviceItems.slice(0, serviceItems.length)" :cols="smallerButtons ? 2 : 4" :key="item.icon" @click="selectService(index)">
                    <v-btn rounded active :class="activeService == index ? 'services-box-active-mobile' : 'services-box-mobile'">
                        <img :src="activeService == index ? item.iconActive : item.icon" height="30px" aspect-ratio="1"/>
                    </v-btn>
                  </v-col>
                </v-row>
                    <v-card hover color="#e6e8e8" style="white-space: pre-line; color: #051238; height: 100%; background-size: auto 50%; background-repeat: no-repeat; background-position: right 10px bottom 10px; padding: 20px;" v-bind:style="{'background-image': `url(${serviceItems[activeService].icon})`}" :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph white-section'" >
                        <div class="section-title-mobile">{{serviceItems[activeService].label}}</div>
                        <div>{{serviceItems[activeService].description}}</div>
                    </v-card>
            </v-row>
            <v-row v-if="!mobile">
              <v-col cols=1></v-col>
              <v-col cols=5>
                <v-row>
                  <v-col v-for="(item, index) in serviceItems.slice(0, serviceItems.length)" :key="item.icon" cols=4 @click="selectService(index)">
                    <v-btn rounded active :class="activeService == index ? 'services-box-active' : 'services-box'">
                      <div style="margin: 0 auto;">
                        <img :src="activeService == index ? item.iconActive : item.icon" height="80px" aspect-ratio="1"/>
                        <br/>
                        <p style="margin-top: 10px;">{{item.label}}</p>

                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=5>
                <v-card hover color="#e6e8e8" style="white-space: pre-line; color: #051238; height: 100%; background-size: auto 50%; background-repeat: no-repeat; background-position: right 10px bottom 10px;" v-bind:style="{'background-image': `url(${serviceItems[activeService].icon})`}" class="section-paragraph white-section" >
                <div>{{serviceItems[activeService].description}}</div>
                </v-card>
              </v-col>
              <v-col cols=1></v-col>
            </v-row>
          </v-container>
          </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import ReportTable from '@/components/ReportTable.vue'

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
      windowWidth: null,
      mobile: null,
      smallerButtons: null,
      activeService: 0,
      serviceItems: [
        { icon: "/home/services/epidemic-globe.svg", iconActive: "/home/services-active/epidemic-globe.svg", label: "Early epidemic detection, globally", description: "EPIWATCH is an artificial intelligence-driven system harnessing vast, open-source data to generate automated early warnings for epidemics worldwide. It has full language and geographic information system capability, using our leading expertise in field epidemiology and epidemic response. EPIWATCH is proven to identify outbreak signals earlier than traditional laboratory or hospital-based surveillance and can provide a trigger to investigate an early outbreak signal." },
        { icon: "/home/services/magnify-epidemic.svg", iconActive: "/home/services-active/magnify-epidemic.svg", label: "Modelling and simulation", description: "We provide a range of customised tools including:\n • Prioritise response to outbreaks (red, amber, green light)\n • Geographical Information Systems for global hot-spot analysis\n • Forecasting and prediction of epidemic trajectory\n • Choosing between alternative epidemic control strategies\n • Simulations customized to your needs, including internal and external spaces\n • Surge capacity predictions for hospitals and health systems " },
        { icon: "/home/services/shield.svg", iconActive: "/home/services-active/shield.svg", label: "Real-time decision support", description: "EPIWATCH monitors global epidemics intelligence and provides real-time epidemic signals in outbreak alerts. The risk analysis tools within EPIWATCH system provides rapid analysis of epidemics for use by the first responders, governments, policy makers, and other stakeholders. We provide flexible access to EPIWATCH on the web, on smartphone Apps and in a live decision theatre for government and non-government stakeholders to understand potential threats and optimal intervention methods through informed, interactive decision making."},
        { icon: "/home/services/desktop-virus.svg", iconActive: "/home/services-active/desktop-virus.svg",label: "Rapid risk analysis tools", description: "EPIWATCH has seamless integration of risk analysis tools including FLUCAST and EPIRISK designed for real-time risk prediction of identified disease events having a serious outcome, to prioritise response and urgency of intervention. Tailored user interfaces on the web and mobile applications are developed for real-time decision support.\n\nWe have a substantial track record in published research on differentiating natural and unnatural epidemics, including use of risk analysis tools to do so. This includes the Modified Grunow Finke Criteria, using a published method we developed to improve the sensitivity and specificity of the most commonly used tool for determining origins, the Grunow Finke Criteria. We can perform an analysis for any given outbreak." },
        { icon: "/home/services/epidemic-phone.svg", iconActive: "/home/services-active/epidemic-phone.svg", label: "Rapid epidemic reports", description: "Watching Briefs are intended as a resource for public health and other first responders in the field, who may not have the time to review past outbreaks or disease details. These reports are a critical analysis on global infectious disease outbreaks of interest using field data or open source data. Watching Briefs are reviewed by trained field epidemiologists who will work with authors to develop the briefs. Please contact us for any requests for Watching Briefs, resources and other information." },
        { icon: "/home/services/big-virus.svg", iconActive: "/home/services-active/big-virus.svg", label: "Customizable alerts", description: "EPIWATCH team has capability to customize alerts for epidemic-related and non-epidemic related events. The customised alerts can be added to EPIWATCH system, using artificial intelligence technology and open source data to generate automated information." },
      ]
    }),
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
  },
  mounted() {
    this.checkScreen();
  },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 500) {
            this.mobile = true;
            this.smallerButtons = false;
        } else if (this.windowWidth <= 1000) {
            this.mobile = true;
            this.smallerButtons = true;
        } else {
            this.smallerButtons = false;
            this.mobile = false;
        }
      },
      selectService(id) {
        this.activeService = id;
        return;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

iframe {
  overflow: hidden;
  pointer-events: auto;
}
</style>