<template>
    <div>
    <div v-if="!mobile" class="blue-section">
        <div class=section-title style="text-align: center">Products</div>
        <div style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%; margin-bottom: -30px;">
    <div v-for="(item, index) in products" :key="item.title" :id="item.title" style="width: 100%; display: flex; flex-direction: row; color: #051238; margin-bottom: 30px;">
      <div
        style="flex: 2; position: relative; background-color: white; padding: 50px;"
      >
      <div :id="'inner-description' + index" style="min-width: 300px;">
        <div class="section-title" >{{item.title}}</div>
        <div class="section-paragraph">{{item.description}}
        </div>
        <br/>
<v-btn :href="item.buttonLink" target="_blank" x-large class="red-button">{{item.buttonText}}</v-btn>
</div>

      </div>
      <div
      :id="'image-right' + index"
        style="
          flex: 3;
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: right center;
          height: inherit;
        "
        v-bind:style="{ 'background-image': `url('${item.img}')`}"
      >
        <svg
          :id="'image-wave-' + index"
          xmlns="http://www.w3.org/2000/svg"
          style="float: left;"
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 12 843 800"
        >
          <path
            fill="#fff"
            fill-opacity="1"
            d="M 489.537 1373.669 L 478.361 1340.811 C 466.792 1308.022 445.066 1242.397 395.957 1180.992 C 347.364 1120.207 272.406 1063.664 260.598 996.697 C 248.617 928.745 299.663 849.68 298.392 780.855 C 296.606 711.41 243.384 651.441 199.593 589.098 C 156.497 527.242 122.919 463.504 121.77 393.643 C 120.777 324.667 150.175 249.521 133.443 183.422 C 116.554 116.437 51.549 58.748 19.571 28.847 L -12.627 0 L -12.627 0 L -6.671 33.779 C 0 67.558 0 134.919 0 202.871 C 0 270.133 0 337.789 0 404.756 C 0 472.708 0 540.66 0 607.626 C 0 675.283 0 742.545 0 801.899 C 0 877.858 0 803.899 0 997.899 C 0 1160.899 0 997.899 0 1148.899 C 0 1340.899 0 1148.899 0 1307.899 L 0 1373.669 Z"
          ></path>
        </svg>
      </div>
    </div>
    </div>
        </div>
              <div v-if="mobile" class="blue-section-mobile">
        <div class="section-title-mobile" style="text-align: center">Products</div>
        <div style="width: 100%; margin: 0 auto; height: 100%; margin-bottom: -30px;">
    <div v-for="(item) in products" :key="item.title" :id="item.title" style="width: 100%; color: #051238; margin-bottom: 30px;">
      <div
        style="
          height: 10vh;
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: right center;
        "
        v-bind:style="{ 'background-image': `url('${item.img}')`}"
      >
      </div>
      <div
        style="flex: 2; position: relative; background-color: white; padding: 20px;"
      >
        <div class="section-title-mobile" >{{item.title}}</div>
        <div class="section-paragraph-mobile">{{item.description}}
        </div>
        <br/>
<v-btn :href="item.buttonLink" target="_blank" x-large class="red-button">{{item.buttonText}}</v-btn>

      </div>
    </div>
    </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import ReportTable from '@/components/ReportTable.vue'

export default {
  name: 'ProductsSection',
  components: {
  },
  data: () => ({
      mobile: null,
      windowWidth: null,
      products: [
        {
          title: 'Outbreak Alerts',
          description: 'The EPIWATCH ‘Global Eye’ monitors a wide variety of data generated around the world 24/7 through its AI-driven data collection including news casts, social platforms and medical reporting using over 46 global languages including the major languages of Asia. We can tap into chatter in communities and local news reports to find out about outbreaks long before health officials are notified.',
          buttonText: 'Go to Outbreak Alerts',
          buttonLink: '/map',
          img: '/products/outbreak-alerts.webp'
        },
        {
          title: 'EPISCOPE',
          description: 'The EPIWATCH team of expert analysts meets weekly to review the outbreak alerts, to analyse early signals of epidemics, and identify important or high threat outbreaks. We summarise the epidemic information and provide stakeholders with the EPISCOPE weekly digest, which includes a global view of outbreak alerts, the top five diseases and epidemic areas, and the featured emerging and ongoing outbreaks information. Custom reports are avaiable on request to clients.',
          buttonText: 'Go to EPISCOPE',
          buttonLink: '/episcope',
          img: '/products/episcope_meet.png'
        },
        {
          title: 'Watching Briefs',
          description: 'Watching Briefs are prepared on request on selected outbreaks of concern. The Watching Briefs include rapid literature review, analysis of available data, trends in case notifications, critical analysis, comparisons with past outbreaks, unusual features and key questions.',
          buttonText: 'Go to Watching Briefs',
          buttonLink: 'https://jglobalbiosecurity.com/articles/?f=2&order=date_published&app=25',
          img: '/products/watching-briefs-3.jpg'
        },
        {
          title: 'EPIRISK',
          description: 'EPIRISK is a rapid risk analysis tool which incorporates disease-related and country-related risk parameters into scoring framework to predict and classify the risk of epidemics, and to provide an early warning for the management of global outbreaks.\nAvailable to clients on our internal dashboard. Please contact us for interest.',
          buttonText: 'Go to EPIRISK study',
          buttonLink: 'https://gh.bmj.com/content/bmjgh/5/6/e002327.full.pdf',
          img: '/products/epirisk-3.jpg'
        },
        {
          title: 'FLUCAST',
          description: 'FLUCAST is a predictive tool designed to provide an indicative forecast of the severity of seasonal influenza activity, using available real-time data, which has been validated with 10 years retrospective seasonal influenza data. Available to clients on our internal dashboard.',
          buttonText: 'Inquire here',
          buttonLink: '/contact',
          img: '/products/flucast-4.png'
        },
        {
          title: 'ORIGINS',
          description: 'We have a substantial track record in published research on differentiating natural and unnatural epidemics, including use of risk analysis tools to do so. This includes the ORIGINS tool, using a published method we developed with high sensitivity and specificity of the most commonly used tool for determining origins, the Grunow Finke Criteria. We can perform an analysis for any given outbreak on request.',
          buttonText: 'Inquire here',
          buttonLink: '/contact',
          img: '/products/origins.png'
        },
        {
          title: 'Biothreat Training',
          description: 'We provide custom online pandemic preparedness exercises for stakeholders from different response perspectives such as health, defence, law enforcement, counter-terrorism, foreign affairs or emergency management and a background in health is not essential. The training is immersive and engaging and designed to help identify gaps, vulnerabilities and weaknesses in responses at local, national and global levels and also encouraging formulation of effective epidemic control solutions.',
          buttonText: 'Inquire here',
          buttonLink: '/contact',
          img: '/products/biothreat-exercise.jpg'
        }
      ],
      headerButtons: ['Outbreak Alerts', 'EPISCOPE', 'Watching Briefs', 'EPIRISK', 'FLUCAST', 'Biothreat Training', 'Origins']
    }),
    created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    mounted() {
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }

          for (let i = 0; i < this.products.length; i++) {
            if (document.getElementById("inner-description" + i)) {
              let innerSectionHeight = document.getElementById("inner-description" + i).clientHeight;
              if (document.getElementById("image-right" + i)) {
                document.getElementById("image-right" + i).setAttribute("style", "flex: 3; max-height: calc("+innerSectionHeight+"px + 100px);background-color: gray; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; height: inherit; background-image: url('"+this.products[i].img+"')")
                document.getElementById("image-right" + i).style.maxHeight = "calc(" + innerSectionHeight + "px + 100px)"
              }
              if (document.getElementById("image-wave-" + i)) {
                document.getElementById("image-wave-" + i).setAttribute("style", "float: left; height: calc("+innerSectionHeight+ "px + 100px);")
                document.getElementById("image-wave-" + i).style.height = "calc(" + innerSectionHeight + "px + 100px)"
              }
            }
          }
        return;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>